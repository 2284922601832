<template>
  <div>
    <b-sidebar
      id="sidebar-bill-details"
      sidebar-class="sidebar-lg"
      :visible="importPaymentsSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateImportPaymentsSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Importar pagamentos</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="m-2">
          <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col cols="12" class="mb-1">
                <b-form-group
                  label="Link da planilha"
                  label-for="sheet"
                > 
                  <b-form-input
                    id="sheet"
                    v-model="sheetUrl"
                    :class="{ 'is-invalid': v$.sheetUrl.$error }"
                    autocomplete="off"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.sheetUrl.required.$invalid">
                      Você deve informar link para planilha
                    </span>
                    <span v-if="!v$.sheetUrl.required.$invalid &&
                      v$.sheetUrl.isTsvFormat.$invalid
                    ">
                      Você deve informar link para planilha em formato tsv
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-1">
                <b-form-group
                  label="Mês"
                  label-for="import-payment-month"
                >
                  <flat-pickr
                    id="import-payment-month"
                    v-model="month"
                    class="form-control"
                    :config="flatPickrConfigInvoice"
                    :class="{
                      'is-invalid':
                        v$.month.$error
                    }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.month.required.$invalid">
                      Você deve informar o mês de importação
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-1">
                <percentage-input
                  id="import-payment-percentage"
                  label="Percentual de imposto"
                  v-model="tax"
                  :thereIsError="v$.tax.$error && v$.tax.$invalid"
                  errorMessage="Você deve fornecer uma porcentagem válida"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-md-0 mb-2">
                <b-button
                  variant="primary"
                  type="submit"
                >
                  <feather-icon icon="UploadIcon" class="mr-50" />
                  <span>Importar</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import PercentageInput from '@/modules/shared/components/PercentageInput';
import Ripple from "vue-ripple-directive";
import useAppConfig from "@core/app-config/useAppConfig";
import * as types from "../store/types";
import { percentageToFloat } from '@/helpers/converters'


export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
    PercentageInput,
    flatPickr
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sheetUrl: undefined,
      month: undefined,
      tax: '15,000 %',
      flatPickrConfigInvoice: {
        altFormat: 'j M Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Portuguese,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: 'Y-m', 
            altFormat: "F Y",
            theme: this.skin
          })
        ] 
      },
    };
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), v$: useVuelidate(), skin };
  },
  validations() {
    return {
      sheetUrl: { 
        required,
        isTsvFormat: (value) => {
          return value.endsWith('tsv');
        }
       },
      month: { required },
      tax: { required }
    }
  },
  computed: {
    ...mapGetters({
      importPaymentsSidebar: types.IMPORT_PAYMENTS_SIDEBAR
    }),
  },
  methods: {
    ...mapMutations({
      mutateImportPaymentsSidebar: types.MUTATE_IMPORT_PAYMENTS_SIDEBAR
    }),
    ...mapActions({
      importFinancialSolutionPayments: types.IMPORT_FINANCIAL_SOLUTION_PAYMENTS
    }),
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      const month = parseInt(this.month.split('-')[1])
      const year = parseInt(this.month.split('-')[0])
      this.importFinancialSolutionPayments({
        sheet_url: this.sheetUrl,
        month,
        year,
        tax: percentageToFloat(this.tax)
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Os pagamentos foram agendados para importação!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateImportPaymentsSidebar({ visible: false });
          setTimeout(() => {
            this.importPaymentsSidebar.saveAction()
          }, 500)
          
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text:  "Ocorreu um erro ao salvar a importação. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    clear() {
      this.sheetUrl = undefined
      this.month = undefined
      this.tax = undefined  
      this.v$.$reset()
    }
  },
};
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>
